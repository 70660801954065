import axios from './index';
export default {
  // 分页查询
  page: (params) => {
    params.chargestatus = 1
    return axios.get('/user-charge-record/refund/listpage', { params });
  },

  // 编辑
  audit: (data) => {
    return axios.put('/user-charge-record/refund', data);
  },

};
