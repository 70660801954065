<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <el-form :inline="true">
            <el-form-item label="昵称/手机号 ：">
              <el-input size="small" placeholder="请输入" v-model="filterData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item label="结束时间 ：">
              <el-date-picker v-model="filterData.time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
       
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="80"> </el-table-column>
          <el-table-column prop="nickname" label="昵称" min-width="100"> </el-table-column>
          <el-table-column prop="tel" label="手机号" min-width="100"> </el-table-column>
          <el-table-column prop="realname" label="客户姓名" min-width="100"> </el-table-column>
          <el-table-column prop="chargeAmount" label="申请提现金额" min-width="100"> </el-table-column>
          <el-table-column prop="refundApplyTime" label="申请时间" min-width="100"> </el-table-column>
          <!-- <el-table-column prop="refundApplyTime" label="退款时间" min-width="100"></el-table-column> -->
          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要通过该笔退款审核吗？'" cancel-button-text="拒绝" confirm-button-text="通过" @confirm="handleConfirm(scope.row)" @cancel="handleCancelConfirm(scope.row)" placement="bottom-end">
                <el-button type="text" slot="reference" @click.native.stop="" style="color:red">审核</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 添加、编辑，模态框组件 -->
      <el-dialog title="请确认退款" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
        <el-result icon="warning" title="警告提示" subTitle="请确认该用户是否在拍车过程中！">
          <template slot="extra">
            
          </template>
        </el-result>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSubmit()">审核通过</el-button>
        </div>
      </el-dialog>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle">
          <el-button type="primary" size="small" @click="handleEdit(drawerDetailObj, 'form')">编辑</el-button>
          <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + drawerDetailObj.salesTitle + '】记录吗？'" @confirm="handleDeleteConfirm(drawerDetailObj)" placement="bottom-end">
            <el-button type="danger" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="拍卖名称" :liValue="drawerDetailObj.salesTitle"></DrawerDetail>
              <DrawerDetail liLabel="拍卖公告" :liValue="drawerDetailObj.salesNotice"></DrawerDetail>
              <DrawerDetail liLabel="车辆类型" :liValue="drawerDetailObj.salesType" :valueArr="this.salesTypeOptions"></DrawerDetail>
              <DrawerDetail liLabel="关联车辆" :liValue="drawerDetailObj.carsNum"></DrawerDetail>
              <DrawerDetail liLabel="截止时间" :liValue="drawerDetailObj.salesEndtime"></DrawerDetail>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/refund';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
  },

  data() {
    return {
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        keyword: '',
        time: [],
      },

      salesTypeOptions: [
        { label: '过户车', value: 1 },
        { label: '债权车', value: 2 },
      ],

      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      //模态框
      isEdit: false,
      idEdit: '',
      dialogVisible: false,
      dialogTitle: '添加',

      drawerDetailVisible: false,
      drawerDetailObj: {},

      form: {
        salesTitle: '',
        salesNotice: '',
        salesEndtime: '',
        salesType: 1,
      },
    };
  },

  mounted() {
    this.getTableList();
  },

  methods: {
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        console.log(res);
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      this.searchParams.startTime = this.filterData.time[0];
      this.searchParams.endTime = this.filterData.time[1];
      delete this.searchParams.time;

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }

      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }

      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },

    //
    handleConfirm(row) {
      this.idEdit = row.id;
      this.dialogVisible = true;
    },

    handleSubmit() {
      let data = {
        id: this.idEdit,
        chargestatus: 2,
      };
      Request.audit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('操作成功，已通过');
          this.getTableList();
          this.dialogVisible = false;
        } else {
          this.$message.error('操作失败，请检查');
        }
      });
    },

    handleCancelConfirm(row) {
      let data = {
        id: row.id,
        chargestatus: 3,
      };
      Request.audit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('操作成功，已拒绝');
          this.getTableList();
        } else {
          this.$message.error('操作失败，请检查');
        }
      });
    },
  },
};
</script>

<style lang="scss" scope></style>
